<template>
	<div>
		<a-modal v-model:visible="visible" width="800px" @cancel="close">
			<template #title>
				<modalHeader title="盘盈入库"></modalHeader>
			</template>
			<!-- <div v-if="isApply"> -->
				<template #footer>
						<a-button v-if="isApply" @click="close">取消</a-button>
						<a-button v-if="isApply" @click="onSubmit" type="primary"> 提交入库 </a-button>
				</template>
			<!-- </div> -->
			<a-spin :spinning="loading" v-if="isApply">
				<a-form ref="formRef" :model="formModel" :label-col="{ span: 7 }">
					<a-row>
						<a-col :span="12">
							<a-form-item name="code" label="资产编号" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-input v-model:value="formModel.code" placeholder="请输入资产编号"></a-input>
							</a-form-item>
							<a-form-item name="name" label="资产名称" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-input v-model:value="formModel.name" placeholder="请输入资产名称"></a-input>
							</a-form-item>
							<a-form-item name="gainTime" label="取得日期" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-date-picker v-model:value="formModel.gainTime"></a-date-picker>
							</a-form-item>
							<a-form-item name="useType" label="设备用途" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-select v-model:value="formModel.useType" placeholder="请选择设备用途">
									<a-select-option v-for="(item, index) in $store.state.count.usageList" :key="item.id" :value="index + 1">{{ item.name }}</a-select-option>
								</a-select>
							</a-form-item>
							<a-form-item name="model" label="规格型号" class="ui-form__item">
								<a-input v-model:value="formModel.model" placeholder="请输入规格型号"></a-input>
							</a-form-item>
							<a-form-item name="departmentId" label="使用部门" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-select v-model:value="formModel.departmentId" placeholder="请选择所属部门" @change="onDepChange">
									<a-select-option v-for="(item, index) in depList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select>
							</a-form-item>
							<a-form-item name="userId" label="使用人" class="ui-form__item">
								<!-- <a-input v-model:value="formModel." placeholder="请输入使用人"></a-input> -->
								<a-select v-model:value="formModel.userId" allow-clear placeholder="请选择使用人" style="width: 190px;">
									<a-select-option v-for="(item, index) in depUserList" :key="item.id" :value="item.id">{{ item.nickname }}</a-select-option>
								</a-select>
							</a-form-item>
							<a-form-item name="place" label="保存地点" class="ui-form__item">
								<a-input v-model:value="formModel.place" placeholder="请输入保存地点"></a-input>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item name="assetsParameterId" label="资产分类" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<!-- <a-select v-model:value="formModel.assetsParameterId" placeholder="请选择资产分类">
									<a-select-option v-for="(item, index) in $store.state.count.assetClassify" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select> -->
								<a-cascader v-model:value="formModel.assetsParameterId" :options="classifyList" placeholder="请选择资产分类" />
							</a-form-item>
							<a-form-item name="gainType" label="取得方式" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-select v-model:value="formModel.gainType" placeholder="请选择取得方式">
									<a-select-option :value="6">盘盈</a-select-option>
								</a-select>
								<!-- <a-select v-model:value="formModal.cause" allow-clear style="width: 190px;">
									<a-select-option :value="1">盘亏</a-select-option>
								</a-select> -->
							</a-form-item>
							<!-- <a-form-item label="编制情况" class="ui-form__item">
								<a-select v-model:value="formModel." placeholder="请选择编制情况">
									<a-select-option :value="0">在编</a-select-option>
								</a-select>
							</a-form-item> -->
							<a-form-item name="conditionType" label="使用状况" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-select v-model:value="formModel.conditionType" placeholder="请选择使用状况">
									<a-select-option v-for="(item, index) in $store.state.count.useStatusList" :key="item.id" :value="item.code">{{ item.name }}</a-select-option>
									<!-- <a-select-option :value="0">在用</a-select-option>
									<a-select-option :value="1">闲置</a-select-option>
									<a-select-option :value="2">报废</a-select-option> -->
								</a-select>
							</a-form-item>
							<a-form-item name="brand" label="品牌" class="ui-form__item">
								<a-input v-model:value="formModel.brand" placeholder="请输入品牌"></a-input>
							</a-form-item>
							<a-form-item name="amount" label="价值" class="ui-form__item">
								<a-input-number v-model:value="formModel.amount" placeholder="请输入" :min="0" :precision="2"></a-input-number> 元
							</a-form-item>
							<a-form-item name="nature" label="资产归属" class="ui-form__item">
								<a-select v-model:value="formModel.nature" placeholder="请选择资产归属">
									<a-select-option :value="'1'">单位</a-select-option>
									<a-select-option :value="'2'">个人</a-select-option>
									<a-select-option :value="'3'">租借</a-select-option>
								</a-select>
							</a-form-item>
							<a-form-item name="unitId" label="使用单位" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<!-- <a-select @select="getAllDepartmentList" v-model:value="formModel.unitId" placeholder="请选择所属单位">
									<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select> -->
								<a-select v-model:value="formModel.unitId" placeholder="请选择所属单位" disabled>
									<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select>
							</a-form-item>
							<a-form-item name="checker" label="盘点人" class="ui-form__item">
								<a-select v-model:value="formModel.checker" allow-clear placeholder="请选择盘点人" style="width: 190px;">
									<a-select-option v-for="(item, index) in $store.state.count.userList" :key="item.id" :value="item.id">{{ item.nickname }}</a-select-option>
								</a-select>
							</a-form-item>
							<!-- <a-form-item name="unitId" label="盘点人" class="ui-form__item">
								<a-select v-model:value="formModel.unitId" placeholder="请选择所属单位" :rules="[{ required: true, message: '必填项不允许为空' }]">
									<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select>
							</a-form-item> -->
						</a-col>
						<a-col :span="24" style="padding-left: 12px;">
							<a-form-item name="description" label="备注" class="ui-form__item" :label-col="{ span: 3 }">
								<a-input v-model:value="formModel.description" placeholder="请输入备注信息"></a-input>
							</a-form-item>
						</a-col>
						<a-col :span="24" style="padding-left: 12px;">
							<a-form-item name="list" label="设备照片" class="ui-form__item" :label-col="{ span: 3 }">
								<imageUpload v-if="isApply" v-model:value="list" :limit="5" :maxSize="2"></imageUpload>
								<a-image :width="130" v-else v-for="(item,index) in list" :key="index"  :src="item"></a-image>
							</a-form-item>
						</a-col>
					</a-row>
				</a-form>
			</a-spin>
			<a-spin :spinning="loading" v-else>
				<a-form ref="formRef" :model="formModel" :label-col="{ span: 7 }">
					<a-row>
						<a-col :span="12">
							<a-form-item name="code" label="资产编号" class="ui-form__item">
								<!-- <a-input v-model:value="formModel.code" placeholder="请输入资产编号"></a-input> -->
								{{formModel.code}}
							</a-form-item>
							<a-form-item name="name" label="资产名称" class="ui-form__item">
								<!-- <a-input v-model:value="formModel.name" placeholder="请输入资产名称"></a-input> -->
								{{formModel.name}}
							</a-form-item>
							<a-form-item name="gainTime" label="取得日期" class="ui-form__item" >
								<!-- <a-date-picker v-model:value="formModel.gainTime"></a-date-picker> -->
								{{transDateTime(formModel.gainTime)}}
							</a-form-item>
							<a-form-item name="useType" label="设备用途" class="ui-form__item" >
								<!-- <a-select v-model:value="formModel.useType" placeholder="请选择设备用途" disabled>
									<a-select-option v-for="(item, index) in $store.state.count.usageList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select> -->
								<!-- {{formModel.useType}} -->
								<div v-if="formModel.useType === 1">办公</div>
								<div v-if="formModel.useType === 2">教学</div>
								<div v-if="formModel.useType === 3">培训</div>
								<div v-if="formModel.useType === 4">执法</div>
								<div v-if="formModel.useType === 5">窗口</div>
								<div v-if="formModel.useType === 6">专用</div>
								<div v-if="formModel.useType === 7">其他</div>
								<div v-if="formModel.useType === 8">医疗</div>
								<div v-if="formModel.useType === 9">科研</div>
							</a-form-item>
							<a-form-item name="model" label="规格型号" class="ui-form__item">
								<!-- <a-input v-model:value="formModel.model" placeholder="请输入规格型号"></a-input> -->
								{{formModel.model}}
							</a-form-item>
							<a-form-item name="departmentId" label="使用部门" class="ui-form__item" >
								<!-- <a-select v-model:value="formModel.departmentId" placeholder="请选择所属部门">
									<a-select-option v-for="(item, index) in $store.state.count.departmentList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select> -->
								{{formModel.departmentName}}
							</a-form-item>
							<a-form-item name="userId" label="使用人" class="ui-form__item">
								<!-- <a-input v-model:value="formModel." placeholder="请输入使用人"></a-input> -->
								<!-- <a-select v-model:value="formModel.userId" allow-clear placeholder="请选择使用人" style="width: 190px;">
									<a-select-option v-for="(item, index) in $store.state.count.userList" :key="item.id" :value="item.id">{{ item.username }}</a-select-option>
								</a-select> -->
								{{formModel.user}}
							</a-form-item>
							<a-form-item name="place" label="保存地点" class="ui-form__item">
								<!-- <a-input v-model:value="formModel.place" placeholder="请输入保存地点"></a-input> -->
								{{formModel.place}}
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item name="classifyName" label="资产分类" class="ui-form__item">
								<!-- <a-select v-model:value="formModel.assetsParameterId" placeholder="请选择资产分类" disabled>
									<a-select-option v-for="(item, index) in $store.state.count.assetClassify" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select> -->
								{{formModel.classifyName}}
							</a-form-item>
							<a-form-item name="gainType" label="取得方式" class="ui-form__item">
								<!-- {{formModel.gainType}} -->
								<div v-if="formModel.gainType === 1">新购</div>
								<div v-if="formModel.gainType === 2">调拨</div>
								<div v-if="formModel.gainType === 3">接受捐赠</div>
								<div v-if="formModel.gainType === 4">自建</div>
								<div v-if="formModel.gainType === 5">置换</div>
								<div v-if="formModel.gainType === 6">盘盈</div>
								<div v-if="formModel.gainType === 7">自行研制</div>
								<div v-if="formModel.gainType === 8">其他</div>
							</a-form-item>
							<!-- <a-form-item label="编制情况" class="ui-form__item">
								<a-select v-model:value="formModel." placeholder="请选择编制情况">
									<a-select-option :value="0">在编</a-select-option>
								</a-select>
							</a-form-item> -->
							<a-form-item name="conditionType" label="使用状况" class="ui-form__item">
								<!-- <a-select v-model:value="formModel.conditionType" placeholder="请选择使用状况" disabled>
									<a-select-option v-for="(item, index) in $store.state.count.useStatusList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select> -->
								<!-- {{formModel.conditionType}} -->
								<div v-if="formModel.conditionType === 1">在用</div>
								<div v-if="formModel.conditionType === 2">出租</div>
								<div v-if="formModel.conditionType === 3">出借</div>
								<div v-if="formModel.conditionType === 4">闲置</div>
								<div v-if="formModel.conditionType === 5">待处置</div>
							</a-form-item>
							<a-form-item name="brand" label="品牌" class="ui-form__item">
								<!-- <a-input v-model:value="formModel.brand" placeholder="请输入品牌"></a-input> -->
								{{formModel.brand}}
							</a-form-item>
							<a-form-item name="amount" label="价值" class="ui-form__item">
								<!-- <a-input-number v-model:value="formModel.amount" placeholder="请输入" :min="0" :precision="2"></a-input-number> 元 -->
								{{formModel.amount}}元
							</a-form-item>
							<a-form-item name="nature" label="资产归属" class="ui-form__item">
								<!-- <a-select v-model:value="formModel.nature" placeholder="请选择资产归属">
									<a-select-option :value="'1'">单位</a-select-option>
									<a-select-option :value="'2'">个人</a-select-option>
									<a-select-option :value="'3'">租借</a-select-option>
								</a-select> -->
								{{formModel.nature == 1?'单位':(formModel.nature == 2?'个人':'租借')}}
							</a-form-item>
							<a-form-item name="unitId" label="使用单位" class="ui-form__item">
								<!-- <a-select v-model:value="formModel.unitId" placeholder="请选择所属单位" :rules="[{ required: true, message: '必填项不允许为空' }]">
									<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select> -->
								{{formModel.unitName}}
							</a-form-item>
							<a-form-item label="盘点人" class="ui-form__item">
								<!-- <a-input v-model:value="formModel.brand" placeholder="请输入品牌"></a-input> -->
								{{formModel.checker}}
							</a-form-item>
						</a-col>
						<a-col :span="24" style="padding-left: 12px;">
							<a-form-item name="description" label="备注" class="ui-form__item" :label-col="{ span: 3 }">
								<!-- <a-input v-model:value="formModel.description" placeholder="请输入备注信息"></a-input> -->
								 {{formModel.description}}
							</a-form-item>
						</a-col>
						<a-col :span="24" style="padding-left: 12px;">
							<a-form-item name="list" label="设备照片" class="ui-form__item" :label-col="{ span: 3 }">
								<!-- <imageUpload v-if="isApply" v-model:value="list" :limit="5" :maxSize="2"></imageUpload> -->
								<a-image :width="130" v-for="(item,index) in list" :key="index"  :src="item"></a-image>
							</a-form-item>
						</a-col>
					</a-row>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import { getAllDepartmentList, getAllUserList } from '@/service/modules/global.js';
	import { inventoryProfit, lossOrProfitDetail } from '@/service/modules/inventory.js';
	import {getAssetsClassify, getAssetsClassifyNode} from '@/service/modules/property.js';
	import { Icon } from '@/components/icon/icon.js';
	import { mapState } from "vuex";
	import modalHeader from '@/components/modalHeader/index.vue';
	import imageUpload from '@/components/upload/imageUpload.vue';
	export default {
		components: { Icon, imageUpload, modalHeader },
		data() {
			return {
				loading: false,
				visible: false,
				isApply: true,
				depList: [], // 选中单位是的部门列表
				formModel: {
					gainTime: undefined,
					unitId: null
				},
				classifyList: [],
				list: [],
				depUserList: []
			}
		},
		created() {
			this.getAllDepartmentList()
			this.getAssetsClassifyFun()
		},
		computed: {
		  ...mapState("system", ["userInfo"]),
		},
		methods: {
			async getAllDepartmentList() {
				// this.formModal.departmentId = null
				let ret = await getAllDepartmentList({
					unitId: this.$store.state.system.userInfo.unitId
				});
				if (ret.code === 200) {
					this.depList = ret.data
				}
			},
			async getAssetsClassifyFun() {
				// this.formModal.departmentId = null
				let ret = await getAssetsClassify({});
				if (ret.code === 200) {
					this.classifyList = ret.data.map(arr => this.mapTree(arr))
				}
			},
			async getAssetsClassifyNode(id) { // 资产分类回显
				let ret = await getAssetsClassifyNode({
					id
				});
				if (ret.code === 200) {
					this.formModal.assetsParameterId = ret.data.reverse();
				}
			},
			async getDetailData(id) {
				this.loading = true;
				try {
					let ret = await lossOrProfitDetail({
						id
					});
					this.loading = false;
					if (ret.code === 200) {
						let tempFormModel = ret.data.assets;
						let tempStr = ret.data.assets.imgUrl
						let tempArr = tempStr? tempStr.split(','): []
						this.list.push(...tempArr)
						let tempGainTime = ret.data.assets.gainTime
						tempFormModel.gainTime = this.moment(tempGainTime)
						this.formModel = tempFormModel
						this.formModel.checker = ret.data.checker
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async onDepChange(e, isDetail) {
				if (!isDetail) {
					this.formModal.userId = undefined;
					this.depUserList = [];
				}
				try {
					// this.loading = true;
					let ret = await getAllUserList({
						departmentId: e
					});
					// this.loading = false;
					if (ret.code === 200) {
						this.depUserList = ret.data;
					}
				} catch(e) {
					// this.loading = false;
				}
			},
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.formModel));
					let temp = this.transDateTime(postData.gainTime)
					let tempTime = new Date(temp)
					postData.imgUrl = this.list.join(',')
					postData.gainTime = tempTime.valueOf()
					postData.gainTime = this.moment(postData.gainTime).unix();
					let tempLen =  postData.assetsParameterId.length
					postData.assetsParameterId = postData.assetsParameterId[tempLen - 1]
					this.loading = true;
					try {
						let ret = await inventoryProfit(postData);
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success("申请成功");
							this.$emit('refresh');
							this.close();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			// async getAllDepartmentList() {
			// 	console.log("选中单位ID",)
			// 	this.formModel.departmentId = null
			// 	let ret = await getAllDepartmentList({
			// 		unitId: this.$store.state.system.userInfo.unitId
			// 	});
			// 	if (ret.code === 200) {
			// 		this.depList = ret.data
			// 		console.log("depList",this.depList)
			// 	}
			// },
			// onSelDate(dateString){
			// 	console.log('newval',dateString)
			// 	let temp = this.transDateTime(dateString)
			// 	let tempTime = new Date(temp)
			// 	console.log('newval2',tempTime)
			// 	this.formModel.gainTime = tempTime.valueOf()
			// },
			mapTree(arr) {
				const haveChild = Array.isArray(arr.children) && arr.children.length > 0;
				return {
					value: arr.id,
					label: arr.name,
					children: haveChild ? arr.children.map(i => this.mapTree(i)) : []
				}
			},
			open(e) {
				this.formModel.unitId = this.userInfo.unitId
				if(e && e.id) {
					this.getDetailData(e.assetsRecordId || e.skipId)
					this.isApply = false
				}
				this.visible = true;
			},
			close() {
				this.$refs.formRef.clearValidate();
				this.list = []
				this.visible = false;
				this.isApply = true
				this.formModel = {}
				this.depUserList = [];
			}
		}
	}
</script>

<style scoped>
	
</style>